import './style.scss';

var initBlockExpertise = function() {
    const headings = document.querySelectorAll('.heading-secondary');
    headings.forEach(heading => {
        heading.addEventListener('click', function() {
            const wrapper = heading.closest('.expertises-menu-wrapper');
            wrapper.classList.toggle('active');
        });
    });
};

// Fonction pour activer/d�sactiver l'accord�on en fonction de la largeur
function toggleAccordionBasedOnWidth() {

    if (window.innerWidth < 768) {
        initBlockExpertise();
    } else {
        // Si on est en desktop, on retire la classe 'active' de tous les wrappers
        document.querySelectorAll('.expertises-menu-wrapper').forEach(wrapper => {
            wrapper.classList.remove('active');
        });
    }
}

// Initialisation au chargement du DOM
document.addEventListener('DOMContentLoaded', () => {
    toggleAccordionBasedOnWidth();

    // Aper�u pour l'�diteur Gutenberg
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=edeis/menu-expertises', function() {
            toggleAccordionBasedOnWidth();
        });
    }
});